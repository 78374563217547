import React, { useState } from 'react';

import { Alert, Button, Flex, Empty, Typography } from 'antd';
import { useGetUserMemberGroupsQuery, useGetUserOwnedGroupsQuery } from 'api/groups';
import { useGetMeQuery } from 'api/users';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { Title } = Typography;

import CardTitle from 'components/atoms/CardTitle';
import Grid from 'components/atoms/Grid';
import { Spinner } from 'components/atoms/Spinner';
import { StyledCard } from 'components/atoms/styles';

import { GroupForm } from './GroupForm';
import { Invitations } from './Invitations';
import { MemberGroups } from './MemberGroups';
import { OwnedGroups } from './OwnedGroups';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const allowed = ['mafihari@mailinator.com', 'test22@op.pl', 'test@test.pl'];

const Groups = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'group',
  });
  const [create, setCreate] = useState<boolean>(false);
  const { data } = useGetMeQuery();

  const {
    data: memberGroups,
    error: memberGroupsError,
    isLoading: memberGroupsIsLoading,
  } = useGetUserMemberGroupsQuery();

  const {
    data: ownedGroups,
    error: ownedGroupsError,
    isLoading: ownedGroupsIsLoading,
  } = useGetUserOwnedGroupsQuery();

  if (memberGroupsIsLoading || ownedGroupsIsLoading) {
    return <Spinner />;
  }

  // TODO: remove when functionality ready to release
  const email = data?.email;
  if (email && !allowed.includes(email)) {
    return <div>Tworzenie grup nadejdzie niedługo! 🚀</div>;
  }

  if (memberGroupsError || ownedGroupsError) {
    return (
      <Alert
        message={'Wystąpił błąd z pobraniem grup'}
        description={'Nie udało się pobrać '}
        type="error"
        showIcon
      />
    );
  }

  const hasAnyGroup = !!memberGroups?.length || !!ownedGroups?.length;

  return (
    <Grid
      aside={
        <StyledCard
          title={
            <Flex justify="space-between" align="center">
              <CardTitle>Zaproszenia</CardTitle>
            </Flex>
          }
        >
          <Invitations />
        </StyledCard>
      }
    >
      <StyledCard
        title={
          <Flex justify="space-between" align="center">
            <CardTitle>Twoje Grupy</CardTitle>
          </Flex>
        }
      >
        {hasAnyGroup ? (
          <Flex vertical gap={32}>
            {!!memberGroups?.length && (
              <div>
                <Title level={4}>Grupy w których uczestniczysz</Title>
                <MemberGroups memberGroups={memberGroups} />
              </div>
            )}
            {!!ownedGroups?.length && <OwnedGroups ownedGroups={ownedGroups} />}
          </Flex>
        ) : (
          <Empty description={t('noGroups')} />
        )}

        <Flex vertical gap={32}>
          {!create ? (
            <Flex justify={'center'}>
              <Button type="primary" onClick={() => setCreate(true)}>
                Utwórz nową grupę
              </Button>
            </Flex>
          ) : (
            <GroupForm onClose={() => setCreate(false)} />
          )}
        </Flex>
      </StyledCard>
    </Grid>
  );
};

export default Groups;
