import React, { useState } from 'react';

import { EyeFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Empty,
  Table,
  TableColumnsType,
  Form,
  Select,
  Button,
  Tooltip,
} from 'antd';
import {
  apiPageSize,
  Results,
  useGetAvailableWeeksQuery,
  useGetLeagueTeamsQuery,
  useGetUserRankingQuery,
} from 'api/rankings';
import { useGetMeQuery } from 'api/users';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/atoms/Spinner';
import TeamImage from 'components/atoms/TeamImage';

import { RankingUserResults } from './RankingUserResults';
import { UsernameCell } from './UserCell';
import {
  RowWrapper,
  TableWrapper,
  UserNameWrapper,
  FiltersWrapper,
  TeamFilterWrapper,
  LabelWrapper,
} from './styles';

interface DataType {
  key: React.Key;
  rank: number;
  name: string;
  points: number;
  teamName?: string;
  teamImage?: string;
  avatar?: string;
  userId: number;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Pozycja',
    dataIndex: 'rank',
    key: 'rank',
    align: 'right',
    responsive: ['sm'],
    width: 45,
  },
  {
    dataIndex: 'rank',
    key: 'rank',
    align: 'right',
    responsive: ['xs'],
  },
  {
    title: 'Użytkownik',
    responsive: ['xs'],
    render: (record: DataType) => (
      <RowWrapper>
        <UserNameWrapper>
          <UsernameCell
            name={record.name}
            teamName={record.teamName}
            teamImage={record.teamImage}
            avatar={record.avatar}
          />
        </UserNameWrapper>
        {record.points}
      </RowWrapper>
    ),
  },
  {
    title: 'Użytkownik',
    key: 'name',
    align: 'left',
    responsive: ['sm'],
    render: (record: DataType) => (
      <UsernameCell
        name={record.name}
        teamName={record.teamName}
        teamImage={record.teamImage}
        avatar={record.avatar}
      />
    ),
  },
  {
    title: 'Punkty',
    dataIndex: 'points',
    key: 'points',
    align: 'right',
    responsive: ['sm'],
  },
  Table.EXPAND_COLUMN,
];

const getRowClassName = (username: string) => (record: DataType) => {
  // class determining the active user
  const activeUserClass = username === record.name ? 'ant-table-row-selected' : '';

  // class determining the color of the badge
  const rankClass = record.points > 0 ? `rank-${record.rank}` : '';

  return activeUserClass + ' ' + rankClass;
};

export const LeagueRanking = ({ id }: { id: string }) => {
  const [page, setPage] = useState(1);
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [week, setWeek] = useState<number | null>(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'rankings',
  });

  const { data, isLoading, error } = useGetUserRankingQuery({
    leagueId: id,
    page,
    teamId: selectedTeamId === 'none' ? '' : selectedTeamId,
    week,
  });

  const { data: availableWeeks } = useGetAvailableWeeksQuery(id);
  const { data: teams } = useGetLeagueTeamsQuery(id);
  const { data: meData } = useGetMeQuery();

  const ranking = data?.results || [];
  const availableWeeksIdsOptions = [
    {
      value: null,
      label: 'Wszystkie',
    },
    ...(
      availableWeeks?.map((week) => ({
        value: week.id,
        label: week.number,
      })) || []
    ).reverse(),
  ];

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert
        message={t('errorTitle')}
        description={t('errorDescription')}
        type="error"
        showIcon
      />
    );
  }

  const rankingTableData: DataType[] = ranking.map((user: Results) => {
    return {
      key: user.rank + user.user,
      rank: user.rank,
      name: user.user,
      points: user.points || 0,
      avatar: user?.avatar,
      teamName: user?.team?.name,
      teamImage: user?.team?.image,
      userId: user?.user_id,
    };
  });

  const teamSelectOptions = [
    {
      value: 'none',
      label: 'Wszystkie',
    },
    ...(teams?.results.map((team) => ({
      value: String(team.id),
      label: (
        <>
          <TeamImage size={16} src={team.image} />
          {team.name}
        </>
      ),
    })) || []),
  ];

  return (
    <TableWrapper>
      <Table
        size="middle"
        locale={{
          emptyText: <Empty description={'Brak danych do wyświetlenia'} />,
        }}
        title={() => (
          <FiltersWrapper>
            <TeamFilterWrapper>
              <Form.Item
                label={
                  <LabelWrapper>
                    {'Klub'}
                    <Tooltip title="Filtruj ranking po klubach należących do danej ligi. Przynależność do klubu możesz ustawić w swoim profilu.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </LabelWrapper>
                }
                labelCol={{ span: 24 }}
              >
                <Select
                  defaultValue={'none'}
                  optionFilterProp="children"
                  allowClear
                  onChange={(team) => {
                    setSelectedTeamId(team);
                    setPage(1);
                  }}
                  options={teamSelectOptions}
                />
              </Form.Item>
            </TeamFilterWrapper>
            <Form.Item style={{ width: 100 }} label={'Kolejka'} labelCol={{ span: 24 }}>
              <Select
                defaultValue={null}
                optionFilterProp="children"
                allowClear
                onChange={(week) => {
                  setWeek(week);
                  setPage(1);
                }}
                options={availableWeeksIdsOptions}
              />
            </Form.Item>
          </FiltersWrapper>
        )}
        pagination={{
          showSizeChanger: false,
          pageSize: apiPageSize,
          current: page,
          onChange: (newPage) => setPage(newPage),
          total: data?.count || 0,
        }}
        rowClassName={getRowClassName(meData?.username || '')}
        expandable={
          week
            ? {
                columnWidth: 0,
                expandedRowRender: (record) => (
                  <RankingUserResults
                    leagueId={id}
                    selectedUserId={record.userId}
                    week={week}
                  />
                ),
                expandIcon: ({ onExpand, record }) => (
                  <Button
                    icon={<EyeFilled size={12} />}
                    onClick={(e) => onExpand(record, e)}
                    size="small"
                  >
                    Typy
                  </Button>
                ),
              }
            : {}
        }
        dataSource={rankingTableData}
        columns={columns}
      />
    </TableWrapper>
  );
};
