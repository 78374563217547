import { ROUTES } from 'constants/routes';

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { resetMe } from 'store/authSlice';

import { TOKENS } from '../api/auth';

const LogoutForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initState = {
    loggingOut: false,
    loggedOut: false,
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    dispatch(resetMe());

    const logout = () => {
      localStorage.removeItem(TOKENS.ACCESS);
      localStorage.removeItem(TOKENS.ACCESS_REFRESH);
      setState({ ...state, loggingOut: false, loggedOut: true });
    };
    logout();
  }, [dispatch, state]);

  useEffect(() => {
    if (state.loggedOut) {
      navigate(ROUTES.LOGIN);
    }
  }, [state, navigate]);

  return null;
};

export default LogoutForm;
