import styled from 'styled-components';

export const MobileMenuItem = styled.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px 0;
  cursor: pointer;
  fill: ${({ $active }) => ($active ? '#52c41a' : 'white')};
  color: ${({ $active }) => ($active ? '#52c41a' : 'white')};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #001529;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  padding: 0 8px 8px;

  // dark mode
  @media (prefers-color-scheme: dark) {
    background: #1e1e1e;
    border-top: 1px solid ${({ theme }) => theme.token.colorPrimary};};
  }
`;
