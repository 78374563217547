import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { MobileMenuItem, MobileMenuWrapper } from './styles';

interface MobileMenuProps {
  items: {
    label: string;
    to: string;
    icon: React.ReactNode;
  }[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ items }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <MobileMenuWrapper>
      {items.map(({ icon, to }) => (
        <MobileMenuItem
          onClick={() => {
            navigate(to);
          }}
          key={to}
          $active={pathname === to}
        >
          {icon}
        </MobileMenuItem>
      ))}
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
