import React from 'react';

import { TrophyOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';

import Avatar from 'components/atoms/Avatar';

import { AvatarWrapper, IconWrapper, TeamImage, UserNameWrapper } from './styles';

const { Paragraph } = Typography;

export const UsernameCell = ({
  name,
  teamName,
  teamImage,
  avatar,
}: {
  name: string;
  teamName?: string;
  teamImage?: string;
  avatar?: string;
}) => (
  <UserNameWrapper>
    <AvatarWrapper>
      <Avatar size={40} src={avatar} username={name} style={{ cursor: 'initial' }} />
      <IconWrapper>
        <TrophyOutlined />
      </IconWrapper>
    </AvatarWrapper>
    <span>
      <Paragraph>{name}</Paragraph>
      <Flex align="center">
        {teamImage && <TeamImage src={teamImage} />}
        {teamName && <Paragraph type={'secondary'}>{teamName}</Paragraph>}
      </Flex>
    </span>
  </UserNameWrapper>
);
