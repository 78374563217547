import React from 'react';

import { Typography, Flex } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

import CardTitle from 'components/atoms/CardTitle';
import { StyledCard } from 'components/atoms/styles';

import Social from '../../atoms/Social';

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

const SocialCard = () => {
  return (
    <StyledCard
      title={
        <Flex justify="space-between" align="center">
          <CardTitle>Social Media</CardTitle>
        </Flex>
      }
    >
      <div>
        <Paragraph>Odwiedź nasze media społecznościowe!</Paragraph>
        <Wrapper>
          <Social large={true} />
        </Wrapper>
      </div>
    </StyledCard>
  );
};

export default SocialCard;
