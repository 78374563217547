import { Match } from 'api/matches';
import { FieldValues, UseFormWatch } from 'react-hook-form';

export const getMatchesWithBet = (matches: Match[]) =>
  Math.round(
    (matches.filter((match) => match.bet.length > 0).length / matches.length) * 100,
  );

export const getFormMatchesWithBet = (
  matches: Match[],
  watch: UseFormWatch<FieldValues>,
) => {
  return Math.round(
    (matches.filter((match, index) => {
      // check if not null and not undefined
      return (
        watch(`matches.${index}.host_goals`) !== undefined &&
        watch(`matches.${index}.host_goals`) !== null &&
        watch(`matches.${index}.guest_goals`) !== undefined &&
        watch(`matches.${index}.guest_goals`) !== null
      );
    }).length /
      matches.length) *
      100,
  );
};
