import { Card, Layout } from 'antd';
import styled from 'styled-components';

const { Header, Sider } = Layout;

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 0;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
`;

export const StyledSider = styled(Sider)<{ $isVisible?: boolean }>`
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.sm}) {
    // overriding antd styles
    position: fixed !important;
    min-width: 100vw !important;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    ${({ $isVisible }) => !$isVisible && 'display: none;'}
  }
`;

export const StyledHeader = styled(Header)`
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
`;

export const ContentCard = styled(Card)`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto;

  .ant-tabs-tab {
    padding-left: 0 !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.xs}) {
    .ant-card-body {
      padding: 8px;
    }
  }
`;

export const TabWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpointsWidth.lg}) {
    max-width: 200px;
    text-align: left;
    text-wrap: initial;
  }
`;

export const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;
