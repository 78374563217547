import { ROUTES } from 'constants/routes';

import React, { useEffect, useState } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useWindowScroll } from '@uidotdev/usehooks';
import { Layout, Menu, Button, Grid, Dropdown, Typography } from 'antd';
import { useGetMeQuery } from 'api/users';
import { hasToken } from 'api/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthInfo } from 'store/authSlice';

import Avatar from 'components/atoms/Avatar';
import Logo from 'components/atoms/Logo';
import MobileMenu from 'components/atoms/MobileMenu';
import { useScrollTop } from 'hooks/useScrollTop';

import 'theme/sanitize.css';
import 'theme/fonts.css';

const { Title } = Typography;

import {
  desktopMenuItemsConfig,
  dropDownMenu,
  mobileOnlyMenuItemsConfig,
} from './config';
import {
  Wrapper,
  StyledSider,
  LogoWrapper,
  StyledHeader,
  HeaderWrapper,
  ItemsWrapper,
  LeftHeader,
} from './styles';

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const authInfo = useSelector(selectAuthInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (authInfo.isUserLoggedIn === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [authInfo.isUserLoggedIn, navigate]);

  useEffect(() => {
    if (!hasToken()) {
      navigate(ROUTES.LOGOUT);
    }
  }, [navigate]);

  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState(true);
  const [isVisible, seIsVisible] = useState(screens.md);
  const [{ y }] = useWindowScroll();

  useScrollTop();
  const { t } = useTranslation('translation', {
    keyPrefix: 'menu',
  });
  const { pathname } = useLocation();
  const { data } = useGetMeQuery();

  const mainMenuItems = [
    ...desktopMenuItemsConfig,
    ...((!screens.sm && mobileOnlyMenuItemsConfig) || []),
  ];

  return (
    <Wrapper>
      <Layout>
        <StyledSider
          style={{
            position: 'sticky',
          }}
          $isVisible={isVisible}
          trigger={null}
          collapsible
          collapsed={screens.sm ? collapsed : false}
          collapsedWidth={100}
        >
          <LogoWrapper>
            <Logo white />
          </LogoWrapper>

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[
              Object.values(ROUTES).find((route) => route === pathname) ||
                ROUTES.DASHBOARD,
            ]}
            items={[
              ...mainMenuItems.map(({ icon, label, to }) => ({
                label: t(label),
                onClick: () => {
                  if (!screens.md) seIsVisible(false);
                  navigate(to);
                },
                key: to,
                icon,
              })),
            ]}
          />
        </StyledSider>

        <Layout>
          <StyledHeader>
            <HeaderWrapper>
              <LeftHeader>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={
                    screens.sm ? () => setCollapsed(!collapsed) : () => seIsVisible(true)
                  }
                  style={{
                    width: 64,
                    height: 64,
                  }}
                />
                {mainMenuItems.map(({ to, label }) => {
                  if (to === pathname && y && y > 100) {
                    return (
                      <Title level={3} key={to}>
                        {t(label)}
                      </Title>
                    );
                  }

                  return null;
                })}
              </LeftHeader>
              <ItemsWrapper>
                <Dropdown
                  menu={{
                    items: dropDownMenu.map(({ label, key, to }) => ({
                      key,
                      'data-testid': key,
                      label: t(label),
                      onClick: () => navigate(to),
                    })),
                  }}
                  placement="bottomLeft"
                >
                  <span data-testid={'userAvatar'}>
                    {/* for some span wrapper is needed for dropdown to work*/}
                    <Avatar
                      username={data?.username || ''}
                      size={40}
                      src={data?.avatar}
                    />
                    <CaretDownOutlined />
                  </span>
                </Dropdown>
              </ItemsWrapper>
            </HeaderWrapper>
          </StyledHeader>

          <Content
            style={{
              paddingTop: 24,
              paddingLeft: screens.sm ? 24 : 0,
              paddingRight: screens.sm ? 24 : 0,
              paddingBottom: 200,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {!screens.sm && <MobileMenu items={mainMenuItems.slice(0, 4)} />}
    </Wrapper>
  );
};

export default DashboardLayout;
