import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { Group } from 'api/groups';

import { Row } from 'components/atoms/Row';

import { GroupForm } from './GroupForm';

interface EntryProps {
  group: Group;
}

export const Entry = ({ group }: EntryProps) => {
  const [edit, setEdit] = useState<boolean>(false);

  if (edit) {
    return <GroupForm initialData={group} onClose={() => setEdit(false)} />;
  }

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <Row
            title={group.name}
            value={
              <Button
                type="default"
                onClick={() => setEdit(true)}
                icon={<EditOutlined />}
              />
            }
          ></Row>
        }
        description={
          <div>
            <p>{group.description}</p>
          </div>
        }
      />
    </List.Item>
  );
};

interface OwnedGroupsProps {
  ownedGroups?: Group[];
}

export const OwnedGroups = ({ ownedGroups }: OwnedGroupsProps) => {
  return (
    <List
      data-testid="ownedGroups"
      pagination={false}
      dataSource={ownedGroups}
      renderItem={(group: Group, index) => (
        <Entry group={group} data-testid={`ownedGroups-${index}`} />
      )}
    />
  );
};
