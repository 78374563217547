export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_CONFIRMATION: '/register/active',
  FORGOT_PASSWORD: '/forgot-password',
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  GROUPS: '/groups',
  BET: '/bet',
  RESULTS: '/results',
  RANKING: '/ranking',
  FAQ: '/faq',
  PROFILE: '/profile',
  SET_NEW_PASSWORD: '/password_reset/confirm',
  TERMS: '/terms',
  SOCIAL_LANDING: '/social-landing',
  // Future pages
  // FEEDBACK: '/feedback',
  // SETTINGS: '/settings',
};

export const QUERY_KEYS = {
  LEAGUE_ID: 'leagueId',
  TOKEN: 'token',
  UID: 'uid',
};
