import { ROUTES } from 'constants/routes';

import React from 'react';

import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 8px;
`;

interface NoLeaguesProps {
  hasButton?: boolean;
}

const NoLeagues: React.FC<NoLeaguesProps> = ({ hasButton = true }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userLeagues',
  });
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Paragraph>{t('noUserLeagues')}</Paragraph>
      {hasButton && (
        <Button size="large" type="primary" onClick={() => navigate(ROUTES.DASHBOARD)}>
          {t('addLeagues')}
        </Button>
      )}
    </Wrapper>
  );
};

export default NoLeagues;
