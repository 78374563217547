import React from 'react';

import { InputNumber } from 'antd';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

interface InputTextProps {
  name: string;
  control: Control;
  min: number;
  max: number;
  size?: 'large' | 'middle' | 'small';
  defaultValue?: number | null;
}

const StyledInputNumber = styled(InputNumber)`
  width: 60px;
`;

const CustomInputNumber: React.FC<InputTextProps> = ({
  name,
  control,
  size = 'small',
  min,
  max,
  defaultValue,
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ field }) => (
        <StyledInputNumber
          type={'number'}
          min={min}
          max={max}
          data-testid={name}
          size={size}
          {...field}
        />
      )}
    />
  );
};

export default CustomInputNumber;
