import React from 'react';

import { useFetchUserLeagueWeekendResultsQuery } from 'api/matches';

import { Spinner } from 'components/atoms/Spinner';
import { ResultsTable } from 'components/molecules/ResultsTable';

import { ResultsTableWrapper } from './styles';

interface ResultsTableProps {
  leagueId: string;
  selectedUserId?: number;
  week?: number;
}

export const RankingUserResults: React.FC<ResultsTableProps> = ({
  leagueId,
  selectedUserId,
  week,
}) => {
  const { data, isLoading, error } = useFetchUserLeagueWeekendResultsQuery(
    {
      leagueId,
      userId: selectedUserId || 0,
      weekId: week || 0,
    },
    { skip: !week || !selectedUserId },
  );

  if (!data) {
    return <Spinner />;
  }

  return (
    <ResultsTableWrapper>
      <ResultsTable
        matches={data.matches}
        date_start={data.date_start}
        date_end={data.date_end}
        isLoading={isLoading}
        hasError={Boolean(error)}
      />
    </ResultsTableWrapper>
  );
};
