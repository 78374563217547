import React from 'react';

import Icon from '@ant-design/icons';
import { ReactComponent as FacebookSvg } from 'assets/icons/social/facebook.svg';
// eslint-disable-next-line import/order
import { ReactComponent as InstagramSvg } from 'assets/icons/social/instagram.svg';
// eslint-disable-next-line import/order
import { ReactComponent as TwitterXSvg } from 'assets/icons/social/twitterX.svg';

import 'theme/sanitize.css';
import 'theme/fonts.css';
import styled from 'styled-components';
// eslint-disable-next-line import/order
import { Button } from 'antd';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  .anticon {
    font-size: 22px !important;
  }
`;

interface SocialProps {
  large?: boolean;
}

const Social: React.FC<SocialProps> = ({ large }) => {
  return (
    <Wrapper>
      <Button
        size={large ? 'large' : 'middle'}
        href="https://www.facebook.com/people/TypTyppl/61564160331906/"
        icon={<Icon component={FacebookSvg} />}
      />
      <Button
        size={large ? 'large' : 'middle'}
        href="https://x.com/typtyppl"
        icon={<Icon component={TwitterXSvg} />}
      />
      <StyledButton
        size={large ? 'large' : 'middle'}
        href="https://www.instagram.com/typtyp.pl"
        icon={<Icon component={InstagramSvg} />}
      />
    </Wrapper>
  );
};

export default Social;
