import React from 'react';

import { Typography } from 'antd';

const { Title } = Typography;

interface CardTitleProps {
  children: React.ReactNode;
}

const CardTitle: React.FC<CardTitleProps> = ({ children }) => (
  <Title level={3} style={{ marginBottom: 0 }}>
    {children}
  </Title>
);

export default CardTitle;
