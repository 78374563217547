import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { authApi } from '../api/auth';

import { RootState } from './index';

interface User {
  email?: string;
}

interface AuthState {
  user: User;
  isUserLoggedIn: boolean | null;
  isLoading: boolean;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
}

const initialState: AuthState = {
  user: {},
  isUserLoggedIn: null,
  isLoading: true,
  status: 'idle',
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetMe: () => {
      return { ...initialState, isUserLoggedIn: false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
        state.isLoading = false;
        state.isUserLoggedIn = true;
      })
      .addMatcher(authApi.endpoints.login.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        state.isUserLoggedIn = false;
        state.isLoading = false;
        state.error = action.error;
      })
      .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state) => {
        state.isLoading = false;
        if (state.isUserLoggedIn === false) {
          state.isUserLoggedIn = true;
        }
      })
      .addMatcher(authApi.endpoints.refreshToken.matchRejected, (state, action) => {
        state.isUserLoggedIn = false;
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const { resetMe } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthInfo = (state: RootState) => state.auth;
