import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { prepareHeaders } from './utils';

export const TOKENS = {
  ACCESS: 'authTokenAccess',
  ACCESS_REFRESH: 'authTokenRefresh',
};

interface LoginResponse {
  access: string;
  refresh: string;
}

interface LoginPayload {
  email: string;
  password: string;
}

export interface RegisterPayload {
  email: string;
  password: string;
  username: string;
  password2: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/authentication/',
  }),
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  tagTypes: ['RefreshToken'],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({ url: 'login/', method: 'POST', body: body }),
      transformResponse: (response: LoginResponse) => {
        localStorage.setItem(TOKENS.ACCESS, response.access);
        localStorage.setItem(TOKENS.ACCESS_REFRESH, response.refresh);

        return response;
      },
      invalidatesTags: ['RefreshToken'],
    }),
    refreshToken: builder.query<LoginResponse, void>({
      query: () => {
        const refresh = localStorage.getItem(TOKENS.ACCESS_REFRESH);

        return {
          url: 'login/refresh/',
          method: 'POST',
          body: { refresh },
          prepareHeaders,
        };
      },

      transformResponse: (response: LoginResponse) => {
        localStorage.setItem(TOKENS.ACCESS, response.access);

        return response;
      },
      transformErrorResponse() {
        localStorage.removeItem(TOKENS.ACCESS);
        localStorage.removeItem(TOKENS.ACCESS_REFRESH);
      },
    }),
    register: builder.mutation<LoginResponse, RegisterPayload>({
      query: (body) => ({ url: 'register/', method: 'POST', body }),
      transformResponse: (response: LoginResponse) => {
        return response;
      },
    }),
    registerConfirmation: builder.mutation<void, { token: string; uid: string }>({
      query: ({ token, uid }) => ({
        url: 'activate/',
        method: 'POST',
        body: { token, uidb64: uid },
      }),
    }),
    resetPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: 'password_reset/',
        method: 'POST',
        body: { email },
      }),
    }),
    setNewPassword: builder.mutation<
      void,
      { token: string; uid: string; password: string; password2: string }
    >({
      query: ({ token, uid, password, password2 }) => ({
        url: 'password_reset/confirm/',
        method: 'POST',
        body: {
          token,
          uidb64: uid,
          password,
          password2,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenQuery,
  useRegisterMutation,
  useRegisterConfirmationMutation,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
} = authApi;
