import React, { useState } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { Group } from 'api/groups';

import { Row } from 'components/atoms/Row';

import { GroupDetails } from './GroupDetails';

interface EntryProps {
  group: Group;
}

const Entry = ({ group }: EntryProps) => {
  const [details, setDetails] = useState<boolean>(false);

  return (
    <>
      {details ? (
        <GroupDetails {...group} />
      ) : (
        <List.Item>
          <List.Item.Meta
            title={
              <Row
                title={group.name}
                value={
                  <Button
                    onClick={() => setDetails(true)}
                    type="default"
                    color="primary"
                    icon={<EyeOutlined />}
                  />
                }
              ></Row>
            }
            description={
              <div>
                <p>{group.description}</p>
              </div>
            }
          />
        </List.Item>
      )}
    </>
  );
};

interface MemberGroupsProps {
  memberGroups?: Group[];
}

export const MemberGroups = ({ memberGroups }: MemberGroupsProps) => {
  return (
    <List
      data-testid="memberGroups"
      pagination={false}
      dataSource={memberGroups}
      renderItem={(group: Group) => <Entry group={group} />}
    />
  );
};
