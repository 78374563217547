import React, { useRef, useState } from 'react';

import { Tour, TourProps, Grid as AntGrid } from 'antd';
import { useGetUserLeaguesQuery } from 'api/leagues';
import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import { LeagueSearch } from 'components/molecules/LeagueSearch';
import SocialCard from 'components/molecules/SocialCard/SocialCard';
import { UserDataDisplay } from 'components/molecules/UserDataDisplay';
import { UserLeagues } from 'components/molecules/UserLeagues';

// TODO: Uncomment when Groups component is ready
// import { Groups } from './Groups';

import { UserStats } from './UserStats';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.cardsGap};
`;

const { useBreakpoint } = AntGrid;

const Dashboard = () => {
  const { data: userLeagues } = useGetUserLeaguesQuery();
  const screens = useBreakpoint();

  const [open, setOpen] = useState<boolean>(userLeagues?.length === 0);
  const searchRef = useRef(null);
  const userLeaguesRef = useRef(null);
  const steps: TourProps['steps'] = [
    {
      title: 'Dodaj swoją pierwszą ligę',
      description: 'Wybierz ligę, w której chcesz brać udział.',
      target: () => searchRef.current,
      nextButtonProps: { children: 'Dalej' },
    },
    {
      title: 'Tutaj znajdziesz swoje ligi',
      description: 'Możesz stąd przejść bezpośrednio do obstawiania wybranej ligi.',
      target: () => userLeaguesRef.current,
      nextButtonProps: { children: 'Koniec' },
      prevButtonProps: { children: 'Wstecz' },
    },
  ];

  return (
    <Grid
      aside={
        <>
          <LeagueSearch tourRef={searchRef} />
        </>
      }
    >
      <Wrapper>
        <UserDataDisplay />
        <UserStats />
        {/*<Groups />*/}
        <span ref={userLeaguesRef}>
          <UserLeagues />
        </span>
        <SocialCard />
      </Wrapper>

      {!screens.xs && (
        <Tour
          disabledInteraction
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
        />
      )}
    </Grid>
  );
};

export default Dashboard;
