import React from 'react';

import { useTranslation } from 'react-i18next';

import CardTitle from 'components/atoms/CardTitle';
import DataNavigation from 'components/molecules/DataNavigation/DataNavigation';
import { ContentCard } from 'components/templates/DashboardLayout/styles';

import LeagueResults from './LeagueResults';

const Results: React.FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'results',
  });

  return (
    <ContentCard title={<CardTitle>{t('title')}</CardTitle>}>
      <DataNavigation ContentComponent={LeagueResults} />
    </ContentCard>
  );
};

export default Results;
