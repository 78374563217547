import React, { useEffect, useState } from 'react';

import { theme } from 'antd';
import { ConfigProvider } from 'antd';
import { useRefreshTokenQuery } from 'api/auth';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { hasToken } from './api/utils';
import { GlobalStyle } from './components/GlobalStyles';
import { router } from './routing';
import { styledComponentsTheme, antdDarkTheme, antdLightTheme } from './theme';
import { initGA, logPageView } from './utils/analytics';

// set interval to 4 minutes, access token is valid for 5 minutes
// refreshToken is used to get new access token
const interval = 4 * 60 * 1000;
const TRACKING_ID = 'G-NM1NR39YJV'; // Zamień na swój identyfikator śledzenia

initGA(TRACKING_ID);

function App() {
  const { token: antDefaultTokens } = theme.useToken();
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  useEffect(() => {
    logPageView();
  }, []);

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = ({ matches }: { matches: boolean }) => setIsDarkMode(matches);
    matcher.addEventListener('change', onChange);

    return () => {
      matcher.removeEventListener('change', onChange);
    };
  }, []);

  useRefreshTokenQuery(undefined, {
    skip: !hasToken(),
    pollingInterval: interval,
  });

  const customTokens = isDarkMode ? antdDarkTheme : antdLightTheme;

  return (
    <>
      <GlobalStyle />
      <ThemeProvider
        theme={{
          ...styledComponentsTheme,
          token: { ...antDefaultTokens, ...customTokens.token },
        }}
      >
        <ConfigProvider theme={customTokens}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
