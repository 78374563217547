import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Popconfirm, Typography } from 'antd';
import {
  CreateGroupPayload,
  Group,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useEditGroupMutation,
} from 'api/groups';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputText from 'components/atoms/InputText';
import { useValidationRules } from 'hooks/useValidationRules';

import UserSearch from './UserSearch';

const maxDescriptionLength = 128;
const { Title } = Typography;

interface GroupFormProps {
  onClose: () => void;
  initialData?: Group;
}

export const GroupForm = ({ initialData, onClose }: GroupFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'group',
  });

  const rules = useValidationRules();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [createGroup, { isLoading: isCreateGroupLoading, isError: isCreateGroupError }] =
    useCreateGroupMutation();

  const [editGroup, { isLoading: isEditGroupLoading, isError: isEditGroupError }] =
    useEditGroupMutation();

  const [deleteGroup, { isLoading: isDeleteGroupLoading, isError: isDeleteGroupError }] =
    useDeleteGroupMutation();

  const handleUserFormSubmit = async (data: CreateGroupPayload) => {
    if (initialData) {
      editGroup({ id: initialData.id, ...data });
    } else {
      createGroup(data);
    }
    onClose();
  };

  if (isCreateGroupError || isEditGroupError || isDeleteGroupError) {
    return <Alert message="Wystąpił błąd podczas zapisywania danych" type="error" />;
  }

  return (
    <form
      onSubmit={handleSubmit(({ name, description, members }) =>
        handleUserFormSubmit({ name, description, members }),
      )}
    >
      <Flex vertical gap={32}>
        <div>
          <Title level={4}>
            {initialData ? 'Edycja grupy' : 'Tworzenie nowej grupy'}
          </Title>
          <InputText
            size="large"
            name="name"
            defaultValue={initialData?.name}
            label={t('groupName')}
            control={control}
            errors={errors}
            rules={rules.groupName}
          />
          <InputText
            name="description"
            label={t('description')}
            control={control}
            errors={errors}
            defaultValue={initialData?.description}
            rules={rules.groupName}
            type="textArea"
            maxLength={maxDescriptionLength}
            showCount
          />
          <UserSearch control={control} />
        </div>

        <Flex gap={16} justify="flex-end">
          <Button
            size="large"
            onClick={() => onClose()}
            type="default"
            disabled={isCreateGroupLoading || isEditGroupLoading || isDeleteGroupLoading}
          >
            Anuluj
          </Button>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            disabled={isCreateGroupLoading || isEditGroupLoading || isDeleteGroupLoading}
          >
            {initialData ? 'Zapisz zmiany' : 'Utwórz'}
          </Button>
          {initialData && (
            <Popconfirm
              title="Czy na pewno chcesz usunąć grupę?"
              description="Usunięcie grupy spowoduje usunięcie wszystkich jej członków."
              okText="Tak"
              cancelText="Nie"
              onConfirm={() => deleteGroup({ id: initialData.id })}
            >
              <Button type="default" icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Flex>
      </Flex>
    </form>
  );
};
