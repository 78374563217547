import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { prepareHeaders } from './utils';

export interface League {
  id: string;
  title: string;
  level: string;
  region: string;
}

interface SearchLeaguesResponse {
  count: number;
  results: League[];
}

interface SearchLeaguesQuery {
  search: string;
  page: number;
  pageSize?: number;
}

// it's set on to 20 on backend
export const apiPageSize = 20;

export const leaguesApi = createApi({
  reducerPath: 'leaguesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/leagues/',
    prepareHeaders,
  }),
  tagTypes: ['Leagues'],
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    searchLeagues: builder.query<SearchLeaguesResponse, SearchLeaguesQuery>({
      query: ({ search, page, pageSize = apiPageSize }) =>
        `/list?search=${search}&page=${page}&page_size=${pageSize}`,
      keepUnusedDataFor: 0,
    }),
    getUserLeagues: builder.query<League[], void>({
      query: () => `user-list/`,
      providesTags: ['Leagues'],
    }),
    addUserLeague: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: 'add/',
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: ['Leagues'],
    }),
    deleteUserLeague: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: 'remove/',
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: ['Leagues'],
    }),
  }),
});

export const {
  useAddUserLeagueMutation,
  useDeleteUserLeagueMutation,
  useGetUserLeaguesQuery,
  useSearchLeaguesQuery,
} = leaguesApi;
