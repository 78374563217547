import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { Team } from './teams';
import { prepareHeaders } from './utils';

export interface UserApiResponse {
  username: string;
  email: string;
  date_birth: string;
  location: string;
  date_joined: string;
  team?: Team;
  avatar: string;
}

export interface CommentApiPayload {
  title: string;
  content: string;
}

export interface UserApiPayload {
  avatar: FileList;
  username: string;
  email: string;
  date_birth: string;
  team: string;
  date_joined: string;
  location: string;
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/user',
    prepareHeaders,
  }),
  tagTypes: ['user'],
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    getMe: builder.query<UserApiResponse, void>({
      query: () => `/me/`,
      providesTags: ['user'],
    }),
    postComment: builder.mutation<UserApiResponse, CommentApiPayload>({
      query: (payload) => ({
        url: `/comment/`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateMe: builder.mutation<UserApiResponse, Partial<UserApiPayload>>({
      query: ({ avatar, ...payload }) => {
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
          if (key === 'date_birth' && value) {
            formData.append('date_birth', dayjs(value).format('YYYY-MM-DDThh:mm'));
          } else {
            formData.append(key, value);
          }
        });

        if (avatar && avatar[0]) {
          formData.append('avatar', avatar[0]);
        }

        return {
          url: `/me/`,
          method: 'POST',
          formData: true,
          body: formData,
        };
      },
      invalidatesTags: ['user'],
    }),
  }),
});

export const { useGetMeQuery, useUpdateMeMutation, usePostCommentMutation } = usersApi;
