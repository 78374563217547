import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.sm}) {
    flex-direction: column;
    padding-top: 8px;
  }
`;

const FirstParagraph = styled(Paragraph)`
  width: 50%;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.sm}) {
    width: 100%;
  }
`;
interface RowProps {
  title?: string;
  value: React.ReactNode;
}

export const Row: React.FC<RowProps> = ({ title, value }) => {
  return (
    <>
      <RowWrapper>
        <FirstParagraph>{title}</FirstParagraph>
        {value}
      </RowWrapper>
    </>
  );
};
