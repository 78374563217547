import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  padding-bottom: 16px;
  // This fixes the issue on safari what causes the card to be too wide
  .ant-card-head::before {
    content: none !important;
  }
  .ant-card-head::after {
    content: none !important;
  }
`;
