import React, { useEffect, useMemo } from 'react';

import { Alert, Select, Typography } from 'antd';
import { useGetUserLeaguesQuery } from 'api/leagues';
import { useGetLeagueMatchesQuery } from 'api/matches';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  PointElement,
  Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import CardTitle from 'components/atoms/CardTitle';
import { Spinner } from 'components/atoms/Spinner';
import { StyledCard } from 'components/atoms/styles';
import { getTotalScore } from 'components/molecules/ResultsTable/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  ChartTitle,
  Tooltip,
  PointElement,
  Colors,
);

const { Title } = Typography;

const options = {
  responsive: true,
  backgroundColor: '#52c41a',
  pointBackgroundColor: '#52c41a',
  pointBorderColor: '#52c41a',
  borderColor: 'rgba(82,196,26,0.2)',
  ticks: {
    precision: 0,
  },
  fill: true,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    x: {
      title: {
        display: true,
        text: 'Kolejka',
      },
      grid: {
        display: false,
      },
    },
  },
};

export const UserStats = () => {
  const [selectedWeekId, setSelectedWeekId] = React.useState<string>();
  const { data: userLeagues } = useGetUserLeaguesQuery();

  const leagueData = userLeagues?.[0];
  const id = leagueData?.id;

  useEffect(() => {
    if (leagueData) {
      setSelectedWeekId(id);
    }
  }, [leagueData]);

  const { data, error, isLoading, isFetching } = useGetLeagueMatchesQuery(
    selectedWeekId || '',
    {
      skip: !selectedWeekId,
    },
  );

  const transformedData = useMemo(() => {
    if (!data) {
      return null;
    }

    const results = data.weekends.map((weekend) => {
      const totalScore = getTotalScore(weekend.matches);

      return totalScore;
    });

    return {
      labels: data.weekends.map((weekend) => weekend.number),
      datasets: [
        {
          label: 'punkty',
          data: results,
        },
      ],
    };
  }, [data, id]);

  const selectOptions = useMemo(() => {
    return userLeagues?.map((league) => ({
      label: league.title,
      value: league.id,
    }));
  }, [userLeagues]);

  return (
    <StyledCard title={<CardTitle>Twoje Statystyki</CardTitle>}>
      {Boolean(isLoading) && <Spinner />}
      {Boolean(error) && (
        <Alert
          message={'Wystąpił błąd'}
          description={'Nie udało się pobrać statystyk'}
          type="error"
          showIcon
        />
      )}
      {transformedData && (
        <>
          <Title level={5}>Zdobyte punkty</Title>
          <Select
            style={{ width: 300, margin: '8px 0 20px 0' }}
            placeholder="Wybierz ligę"
            optionFilterProp="children"
            defaultValue={selectedWeekId}
            onChange={(value) => setSelectedWeekId(value)}
            options={selectOptions}
            loading={isFetching}
          />

          <Line data={transformedData} options={options} />
        </>
      )}
    </StyledCard>
  );
};
