import styled from 'styled-components';

export const UserNameWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-typography {
    margin-bottom: 0;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: -8px;
  left: -8px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
`;

export const TableWrapper = styled.span`
  .ant-table-row {
    position: relative;
  }

  ${IconWrapper} {
    display: none;
  }

  .rank-1 {
    ${IconWrapper} {
      display: flex;
      background-color: #ffc400;
    }
  }

  .rank-2 {
    ${IconWrapper} {
      display: flex;
      background-color: #c0c0c0;
    }
  }

  .rank-3 {
    ${IconWrapper} {
      display: flex;
      background-color: #cd7f32;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const TeamFilterWrapper = styled.div`
  width: 200px;
`;

export const ResultsTableWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  width: 100%;
`;

export const TeamImage = styled.img`
  width: 16px;
  margin-right: 8px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
