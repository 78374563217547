import { TOKENS } from './auth';

export const prepareHeaders = (headers: Headers) => {
  const jwtToken = localStorage.getItem(TOKENS.ACCESS);

  if (jwtToken) {
    headers.set('authorization', `Bearer ${jwtToken}`);
  }

  return headers;
};

export const hasToken = () => {
  return !!localStorage.getItem(TOKENS.ACCESS);
};
