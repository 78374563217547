import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationPl from './locales/pl/translation.json';

export const supportedLangs = ['en', 'pl'];

export const supportedLangsLong = [
  { value: 'en-US', name: 'English' },
  { value: 'pl-PL', name: 'Polski' },
];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEn,
    },
    pl: {
      translation: translationPl,
    },
  },
  lng: 'pl', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
