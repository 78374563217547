import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Group } from 'api/groups';

import { Row } from 'components/atoms/Row';

interface GroupDetailsProps extends Group {}

export const GroupDetails = ({ name, description }: GroupDetailsProps) => {
  const handleLeaveGroup = () => {
    console.log('leave group');
  };

  return (
    <div>
      <Row title="Nazwa" value={name} />
      <Row title="Opis" value={description} />
      <Popconfirm
        title="Przestań Uczestniczyć w grupie"
        description="Czy na pewno chcesz opuścić grupę?"
        okText="Tak"
        cancelText="Nie"
        onConfirm={() => handleLeaveGroup()}
      >
        <Button type="default" icon={<DeleteOutlined />} />
      </Popconfirm>
    </div>
  );
};
