import React from 'react';

import { Progress, ProgressProps } from 'antd';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#52c41a',
};

interface StyledProgressProps {
  percent: number;
  type?: 'circle' | 'line';
}

const StyledProgress: React.FC<StyledProgressProps> = ({ percent, type }) => {
  return <Progress type={type} percent={percent} strokeColor={twoColors} />;
};

export default StyledProgress;
