import { QUERY_KEYS } from 'constants/routes';

import React, { useCallback, useLayoutEffect } from 'react';

import { TabsProps, Collapse, Tabs, Grid } from 'antd';
import { useGetUserLeaguesQuery } from 'api/leagues';
import { useSearchParams } from 'react-router-dom';

import NoLeagues from 'components/atoms/NoLeagues';
import { Spinner } from 'components/atoms/Spinner';
import { TabWrapper } from 'components/templates/DashboardLayout/styles';

const { useBreakpoint } = Grid;

interface DataNavigationProps {
  ContentComponent: React.FC<{ id: string }>;
}

const DataNavigation: React.FC<DataNavigationProps> = ({ ContentComponent }) => {
  const { data: userLeagues, isLoading } = useGetUserLeaguesQuery();

  const screens = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const leagueIdParam = searchParams.get(QUERY_KEYS.LEAGUE_ID);

  const items: TabsProps['items'] = userLeagues?.map((userLeague) => {
    return {
      key: userLeague.id,
      label: <TabWrapper>{userLeague.title}</TabWrapper>,
      children: <ContentComponent id={userLeague.id} />,
    };
  });

  const handleSetSearchParams = useCallback(
    (key: string | string[]) => {
      setSearchParams({ [QUERY_KEYS.LEAGUE_ID]: key });
    },
    [setSearchParams],
  );

  useLayoutEffect(() => {
    if (!items) {
      return;
    }

    if (!leagueIdParam && !screens.xs) {
      handleSetSearchParams(items[0]?.key);
    }
  }, [handleSetSearchParams, items, leagueIdParam, screens.xs]);

  const commonProps = {
    items,
    destroyInactiveTabPane: true,
  };

  const onChange = (key: string | string[]) => {
    handleSetSearchParams(key);
  };

  if (userLeagues && userLeagues.length === 0) {
    return <NoLeagues />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return screens.xs ? (
    <Collapse defaultActiveKey={Number(leagueIdParam)} {...commonProps} />
  ) : (
    <Tabs
      onChange={onChange}
      // @ts-expect-error Types require activeKey to be a string but in reality it's working with number not with the string
      activeKey={Number(leagueIdParam)}
      tabPosition={screens.lg ? 'left' : 'top'}
      {...commonProps}
    />
  );
};

export default DataNavigation;
