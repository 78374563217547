import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

export const LeagueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const SeparatorWrapper = styled.div`
  font-size: 12px;
`;

export const TableFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  margin-bottom: 16px;
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const DateWrapper = styled.div`
  padding-top: 16px;
`;

export const TitleWrapper = styled(Title)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;
