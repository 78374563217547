import React from 'react';

import { Input, Form } from 'antd';
import {
  RegisterOptions,
  Control,
  Controller,
  FieldErrors,
  FieldError,
} from 'react-hook-form';
import { formatError } from 'utils';

interface InputTextProps {
  type?: 'email' | 'text' | 'number' | 'tel' | 'password' | 'textArea';
  name: string;
  label?: string;
  control: Control;
  rules: RegisterOptions;
  errors?: FieldErrors;
  placeholder?: string;
  size?: 'large' | 'middle' | 'small';
  help?: string;
  autoComplete?: string;
  defaultValue?: string;
  maxLength?: number;
  showCount?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  type = 'text',
  name,
  label,
  placeholder,
  control,
  rules,
  size,
  errors,
  help,
  autoComplete,
  defaultValue,
  maxLength,
  showCount,
}) => {
  const error = errors?.[name] as FieldError | undefined;

  const displayError = formatError(error);

  const Component = type === 'textArea' ? Input.TextArea : Input;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Form.Item
          labelCol={{ span: 24 }}
          label={label}
          validateStatus={error && 'error'}
          help={error ? displayError : help}
        >
          <Component
            {...field}
            data-testid={name}
            placeholder={placeholder}
            size={size}
            type={type}
            maxLength={maxLength}
            autoComplete={autoComplete}
            showCount={showCount}
          />
        </Form.Item>
      )}
    />
  );
};

export default InputText;
