import { ROUTES } from 'constants/routes';

import React from 'react';

import Icon, { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { ReactComponent as BetSvg } from 'assets/icons/main-menu/bet.svg';
import { ReactComponent as homeSvg } from 'assets/icons/main-menu/home.svg';
// TODO: uncomment when groups are ready
// import { ReactComponent as PeopleSvg } from 'assets/icons/main-menu/people.svg';
import { ReactComponent as QuestionSvg } from 'assets/icons/main-menu/question-mark-circle.svg';
import { ReactComponent as RankSvg } from 'assets/icons/main-menu/rank.svg';
import { ReactComponent as ResultsSvg } from 'assets/icons/main-menu/results.svg';

export const desktopMenuItemsConfig = [
  {
    label: 'dashboard',
    to: ROUTES.DASHBOARD,
    icon: <Icon component={homeSvg} />,
  },
  // {
  //   label: 'groups',
  //   to: ROUTES.GROUPS,
  //   icon: <Icon component={PeopleSvg} />,
  // },
  {
    label: 'results',
    to: ROUTES.RESULTS,
    icon: <Icon component={ResultsSvg} />,
  },
  {
    label: 'bet',
    to: ROUTES.BET,
    icon: <Icon component={BetSvg} />,
  },
  {
    label: 'ranks',
    to: ROUTES.RANKING,
    icon: <Icon component={RankSvg} />,
  },
  {
    label: 'profile',
    to: ROUTES.PROFILE,
    icon: <UserOutlined />,
  },
  {
    label: 'faq',
    to: ROUTES.FAQ,
    icon: <Icon component={QuestionSvg} />,
  },
];

export const mobileOnlyMenuItemsConfig = [
  {
    label: 'logout',
    to: ROUTES.LOGOUT,
    icon: <LogoutOutlined />,
  },
];

export const dropDownMenu = [
  {
    key: 'profile',
    label: 'profile',
    to: ROUTES.PROFILE,
  },
  {
    key: 'logout',
    label: 'logout',
    to: ROUTES.LOGOUT,
  },
];
