import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { SearchTeamsResponse, Team } from './teams';
import { prepareHeaders } from './utils';

export interface Results {
  user: string;
  user_id: number;
  points: number;
  rank: number;
  avatar?: string;
  team?: Team;
}

export interface RankingsApiResponse {
  count: number;
  results: Results[];
}

export interface RankingsApiPayload {
  leagueId: string;
  page: number;
  teamId?: string;
  week?: number | null;
}

export interface RankingsAvailableWeeksApiResponse {
  date_end: string;
  date_start: string;
  id: number;
  number: number;
}

// it's set on to 20 on backend
export const apiPageSize = 20;

export const rankingsApi = createApi({
  reducerPath: 'rankingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/ranking/league/',
    prepareHeaders,
  }),
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    getUserRanking: builder.query<RankingsApiResponse, RankingsApiPayload>({
      query: ({ leagueId, page, teamId = '', week }) => {
        const weekPath = week ? `/weekend/${week}` : '';

        return `${leagueId}${weekPath}?page=${page}&team__id=${teamId}`;
      },
    }),
    getLeagueTeams: builder.query<SearchTeamsResponse, string>({
      query: (leagueId) => `${leagueId}/teams/`,
    }),
    getAvailableWeeks: builder.query<RankingsAvailableWeeksApiResponse[], string>({
      query: (leagueId) => `${leagueId}/available-weeks/`,
    }),
  }),
});

export const {
  useGetUserRankingQuery,
  useGetAvailableWeeksQuery,
  useGetLeagueTeamsQuery,
} = rankingsApi;
