import React from 'react';

import placeholder from 'assets/placeholders/logoclub.svg';
import 'theme/sanitize.css';
import 'theme/fonts.css';
import styled from 'styled-components';

const Img = styled.img`
  margin: 0 8px;
`;

interface TeamImageProps {
  src?: string;
  size?: number;
}

const TeamImage: React.FC<TeamImageProps> = ({ src, size = 24 }) => {
  return <Img width={size} src={src || placeholder} />;
};

export default TeamImage;
