import React from 'react';

import { useTranslation } from 'react-i18next';

import CardTitle from 'components/atoms/CardTitle';
import { DataNavigation } from 'components/molecules/DataNavigation';
import { ContentCard } from 'components/templates/DashboardLayout/styles';

import { LeagueBet } from './LeagueBet';

function Bet() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'bet',
  });

  return (
    <ContentCard data-testid={'betTabs'} title={<CardTitle>{t('title')}</CardTitle>}>
      <DataNavigation ContentComponent={LeagueBet} />
    </ContentCard>
  );
}

export default Bet;
