import dayjs from 'dayjs';
import { FieldError } from 'react-hook-form';

import { DATE_FORMAT } from '../constants';

export const formatDate = (date?: string): string => {
  if (!date) {
    return '-';
  }

  return dayjs(date).format(DATE_FORMAT);
};

export const formatError = (error: FieldError | undefined): string | undefined => {
  if (!error) return;

  return error?.message || 'Unknown error';
};
