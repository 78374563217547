import { ROUTES } from 'constants/routes';

import React from 'react';

import LogoSvgBig from 'assets/logo-big.svg';
import LogoSvg from 'assets/logo.svg';
import { Link } from 'react-router-dom';

import Icon from 'components/atoms/Icon';

import { Wrapper } from './styles';

interface LogoProps {
  white?: boolean;
  big?: boolean;
}

const Logo: React.FC<LogoProps> = ({ white, big }) => {
  return (
    <Wrapper $big={big} $white={white}>
      <Link to={ROUTES.DASHBOARD}>
        <Icon src={big ? LogoSvgBig : LogoSvg} />
      </Link>
    </Wrapper>
  );
};

export default Logo;
