import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { prepareHeaders } from './utils';

export interface Team {
  id: string;
  name: string;
  image: string;
}

export interface SearchTeamsResponse {
  count: number;
  results: Team[];
}

interface SearchTeamsQuery {
  search: string;
  page: number;
  pageSize?: number;
}

// it's set on to 20 on backend
export const apiPageSize = 20;

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/team/',
    prepareHeaders,
  }),
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    searchTeams: builder.query<SearchTeamsResponse, SearchTeamsQuery>({
      query: ({ search, page, pageSize = apiPageSize }) =>
        `/list?search=${search}&page=${page}&page_size=${pageSize}`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useSearchTeamsQuery } = teamsApi;
