import styled from 'styled-components';

export const Wrapper = styled.div<{ $even?: boolean }>`
  width: 100%;
  display: grid;
  gap: ${({ theme }) => theme.dimensions.cardsGap};
  margin: 0 auto;
  grid-template-columns: ${({ $even }) => ($even ? '1fr 1fr' : '2fr 1fr')};
  max-width: ${({ theme }) => theme.dimensions.maxWidth};

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.lg}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.md}) {
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.div``;

export const Aside = styled.div``;
