import React, { useState } from 'react';

import { Select, Form } from 'antd';
import { GroupUser, useSearchUsersQuery } from 'api/groups';
import { Control, Controller } from 'react-hook-form';

interface TeamSearchProps {
  control: Control;
  selectedUsers?: GroupUser[];
  onChange?: () => void;
}

const UserSearch: React.FC<TeamSearchProps> = ({
  control,
  // selectedUsers,
  onChange,
}) => {
  const [searchPhrase, setSearch] = useState('');

  const { data: searchResults, isLoading } = useSearchUsersQuery({
    page: 1,
    pageSize: 10,
    search: searchPhrase,
  });

  const options =
    searchResults?.results.map((user) => ({
      value: String(user.id),
      label: user.username,
    })) || [];

  // const selectedTeamId = selectedUsers?.id && String(selectedUsers.id);

  // const defaultOption = apiOptions.find((option) => option.value === selectedTeamId)
  //   ? null
  //   : selectedTeamId
  //     ? { value: String(selectedTeam.id), label: selectedTeam.name }
  //     : null;

  // if (defaultOption) {
  //   options.unshift(defaultOption);
  // }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Controller
      control={control}
      // defaultValue={selectedTeamId || resetOption.value}
      name="members"
      render={({ field }) => (
        <Form.Item labelCol={{ span: 24 }} label="Użytkownicy">
          <Select
            {...field}
            mode="multiple"
            size={'large'}
            loading={isLoading}
            style={{ width: '100%', textAlign: 'left' }}
            showSearch
            placeholder={'Użytkownicy'}
            allowClear={true}
            optionFilterProp="children"
            options={options}
            filterOption={filterOption}
            onSearch={(source) => {
              setSearch(source);
            }}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        </Form.Item>
      )}
    />
  );
};

export default UserSearch;
