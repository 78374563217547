import React from 'react';

import { Wrapper, Main, Aside } from './styles';

interface GridProps {
  aside: React.ReactNode;
  children: React.ReactNode;
  even?: boolean;
}

const Grid: React.FC<GridProps> = ({ aside, children, even }) => {
  return (
    <Wrapper $even={even}>
      <Main>{children}</Main>
      <Aside>{aside}</Aside>
    </Wrapper>
  );
};

export default Grid;
