import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { prepareHeaders } from './utils';

interface Bet {
  guest_goals: number | null;
  host_goals: number | null;
  score: number | null;
}

export interface Match {
  id: string;
  date: string;
  host: { id: string; name: string; image: string };
  guest: { id: string; name: string; image: string };
  matchweek: number;
  bet: Bet[];
  guest_goals: number | null;
  host_goals: number | null;
  additional_info: string;
  result_status: string;
}

export interface Weekend {
  date_end: string;
  date_start: string;
  matches: Match[];
  number: number;
  is_current: boolean;
}

interface BetData {
  match_week: number;
  week_number: number;
  matches: {
    match: string;
    host_goals: number | null;
    guest_goals: number | null;
  }[];
}

interface LeagueMatchesResponseType {
  weekends: Weekend[];
}

export interface LeagueWeekendMatchesToBet {
  matches: Match[];
  match_week: number;
  week_number: number;
  date_start: string;
  date_end: string;
}

export const matchesApi = createApi({
  reducerPath: 'matchesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders,
  }),

  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  tagTypes: ['Matches'],
  endpoints: (builder) => ({
    getLeagueMatches: builder.query<LeagueMatchesResponseType, string>({
      query: (id) => `/match/bet/league/${id}/matches`,
    }),
    fetchLeagueWeekendMatchesToBet: builder.query<
      LeagueWeekendMatchesToBet,
      { id: string }
    >({
      query: ({ id }) => `/match/bet/league/${id}/week`,
      providesTags: ['Matches'],
    }),
    sendBet: builder.mutation<Match[], BetData>({
      query: (data) => ({ url: '/match/bet/weekend/', method: 'POST', body: data }),
      invalidatesTags: ['Matches'],
    }),
    fetchUserLeagueWeekendResults: builder.query<
      LeagueWeekendMatchesToBet,
      { leagueId: string; weekId: number; userId: number }
    >({
      query: ({ leagueId, weekId, userId }) =>
        `/match/league/${leagueId}/week/${weekId}/user/${userId}/`,
    }),
  }),
});

export const {
  useGetLeagueMatchesQuery,
  useFetchLeagueWeekendMatchesToBetQuery,
  useSendBetMutation,
  useFetchUserLeagueWeekendResultsQuery,
} = matchesApi;
