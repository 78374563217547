import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 16px;
    width: 100%;
    min-height: 100vh;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px lightgray inset !important;
  }
  
  * {
    box-sizing: border-box;
  }
`;
