import { QUERY_KEYS, ROUTES } from 'constants/routes';

import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { List, Button, Popconfirm } from 'antd';
import { useGetUserLeaguesQuery, useDeleteUserLeagueMutation, League } from 'api/leagues';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CardTitle from 'components/atoms/CardTitle';
import NoLeagues from 'components/atoms/NoLeagues';
import { StyledCard } from 'components/atoms/styles';

export const ListEntry = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

interface UserLeaguesProps {
  hasDelete?: boolean;
}

const UserLeagues: React.FC<UserLeaguesProps> = ({ hasDelete }) => {
  const navigate = useNavigate();

  const { data: userLeagues } = useGetUserLeaguesQuery();

  const [removeLeague] = useDeleteUserLeagueMutation();

  return (
    <StyledCard title={<CardTitle>Twoje Ligi</CardTitle>}>
      {userLeagues && userLeagues.length > 0 ? (
        <List
          data-testid="userLeagues"
          pagination={false}
          dataSource={userLeagues}
          renderItem={(userLeague: League, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <ListEntry>
                    {userLeague.title}{' '}
                    {hasDelete ? (
                      <Popconfirm
                        title="Przestań obserwować"
                        description="Czy na pewno chcesz przestać obserwować tę ligę?"
                        okText="Tak"
                        cancelText="Nie"
                        onConfirm={() => removeLeague({ id: userLeague.id })}
                      >
                        <Button
                          data-testid={`userLeagues-delete${index}`}
                          type="default"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    ) : (
                      <Button
                        style={{ padding: '0 8px' }}
                        type="default"
                        onClick={() =>
                          navigate({
                            pathname: ROUTES.BET,
                            search: `?${QUERY_KEYS.LEAGUE_ID}=${userLeague.id}`,
                          })
                        }
                      >
                        Typuj
                      </Button>
                    )}
                  </ListEntry>
                }
                description={
                  <div>
                    <p>{userLeague.level}</p>
                    <p>{userLeague.region}</p>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <NoLeagues hasButton={false} />
      )}
    </StyledCard>
  );
};

export default UserLeagues;
