import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_CACHE_SECONDS } from '../constants';

import { prepareHeaders } from './utils';

export interface CreateGroupPayload {
  name: string;
  description: string;
  members: number[];
}

export interface EditGroupPayload extends CreateGroupPayload {
  id: number;
}

export type InvitationStatus = 'accepted' | 'rejected' | 'pending';

export interface Invitation {
  id: number;
  username: string;
  user: number;
  status: InvitationStatus;
}

export interface Invitations {
  results: Invitation[];
  count: number;
}

export interface GroupUser {
  id: number;
  username: string;
}

export interface GroupUsers {
  results: GroupUser[];
  count: number;
}

export interface Group extends CreateGroupPayload {
  id: number;
  image: string;
}

interface GroupUsersQuery {
  search: string;
  page: number;
  pageSize?: number;
}

const apiPageSize = 20;

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL + '/group/',
    prepareHeaders,
  }),
  tagTypes: ['ownedGroups', 'memberGroups', 'invitations'],
  keepUnusedDataFor: Number(API_CACHE_SECONDS),
  endpoints: (builder) => ({
    createGroup: builder.mutation<void, CreateGroupPayload>({
      query: ({ name, description, members }) => ({
        url: 'create/',
        method: 'POST',
        body: { name, description, members },
      }),
      invalidatesTags: ['ownedGroups', 'memberGroups'],
    }),
    editGroup: builder.mutation<void, EditGroupPayload>({
      query: ({ id, name, description, members }) => ({
        url: `${id}/`,
        method: 'PATCH',
        body: { name, description, members },
      }),
      invalidatesTags: ['ownedGroups', 'memberGroups'],
    }),
    deleteGroup: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ownedGroups', 'memberGroups'],
    }),
    getUserOwnedGroups: builder.query<Group[], void>({
      query: () => `owned/`,
      providesTags: ['ownedGroups'],
    }),
    getUserMemberGroups: builder.query<Group[], void>({
      query: () => `member/`,
      providesTags: ['memberGroups'],
    }),
    getGroupInvitations: builder.query<Invitations, void>({
      query: () => `invitations/`,
      providesTags: ['invitations'],
    }),
    updateInvitation: builder.mutation<void, { id: number; status: InvitationStatus }>({
      query: ({ id, status }) => ({
        url: `invitations/${id}/`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['invitations', 'memberGroups'],
    }),
    searchUsers: builder.query<GroupUsers, GroupUsersQuery>({
      query: ({ search, page, pageSize = apiPageSize }) =>
        `/user-search?search=${search}&page=${page}&page_size=${pageSize}`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateGroupMutation,
  useEditGroupMutation,
  useUpdateInvitationMutation,
  useDeleteGroupMutation,
  useGetUserMemberGroupsQuery,
  useGetUserOwnedGroupsQuery,
  useGetGroupInvitationsQuery,
  useSearchUsersQuery,
} = groupsApi;
