import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { theme, Avatar as AntdAvatar } from 'antd';

import 'theme/sanitize.css';
import 'theme/fonts.css';

interface AvatarProps {
  src?: string;
  size?: number;
  style?: React.CSSProperties;
  onClick?: () => void;
  username: string;
}

const Avatar: React.FC<AvatarProps> = ({ src, size = 40, style, onClick, username }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <AntdAvatar
      onClick={onClick}
      size={size}
      style={{ backgroundColor: colorPrimary, cursor: 'pointer', ...style }}
      // icons should be made out of two letters from username
      icon={
        username && username.length > 1 ? (
          <span>{username?.slice(0, 2).toUpperCase()}</span>
        ) : (
          <UserOutlined />
        )
      }
      src={src}
    />
  );
};

export default Avatar;
