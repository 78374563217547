import { ROUTES } from 'constants/routes';

import React, { useEffect } from 'react';

import 'theme/sanitize.css';
import 'theme/fonts.css';

import { Button } from 'antd';
import { Typography } from 'antd';
import { hasToken } from 'api/utils';
import bg from 'assets/images/bgBall.jpeg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthInfo } from 'store/authSlice';
import styled from 'styled-components';

import Logo from 'components/atoms/Logo';

import Social from '../atoms/Social';

const { Title, Paragraph } = Typography;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.token.colorBgBase};
  border-radius: ${({ theme }) => theme.token.colorSuccess};
  background-image: url(${bg});
  background-size: cover;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  padding: 24px;
  overflow: auto;

  @media (prefers-color-scheme: dark) {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.token.colorBgContainer};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.token.boxShadow};
  overflow: hidden;
  padding: 32px 32px 24px;
  max-width: 400px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  text-align: center;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`;

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const authInfo = useSelector(selectAuthInfo);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasToken()) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [authInfo.isUserLoggedIn, navigate]);

  return (
    <Wrapper>
      <Overlay>
        <Modal>
          <Logo big />
          <ContentWrapper>{children}</ContentWrapper>
          <Social />
        </Modal>
      </Overlay>
    </Wrapper>
  );
};

export default AuthLayout;
