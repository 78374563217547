import React from 'react';

import { Input, Form } from 'antd';
import {
  RegisterOptions,
  Control,
  Controller,
  FieldErrors,
  FieldError,
} from 'react-hook-form';
import { formatError } from 'utils';

const { Search } = Input;

interface InputTextProps {
  name: string;
  label?: string;
  control: Control;
  rules: RegisterOptions;
  errors?: FieldErrors;
  placeholder?: string;
  size?: 'large' | 'middle' | 'small';
  allowClear?: boolean;
  help?: string;
  onSearch?: (source: 'input' | 'clear') => void;
}

const InputSearch: React.FC<InputTextProps> = ({
  name,
  label,
  placeholder,
  control,
  rules,
  size,
  errors,
  allowClear,
  help,
  onSearch = () => {},
}) => {
  const error = errors?.[name] as FieldError | undefined;

  const displayError = formatError(error);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Form.Item
          labelCol={{ span: 24 }}
          label={label}
          validateStatus={error && 'error'}
          help={error ? displayError : help}
        >
          <Search
            {...field}
            data-testid={name}
            placeholder={placeholder}
            size={size}
            allowClear={allowClear}
            onSearch={(_, __, source) => onSearch(source?.source || 'input')}
          />
        </Form.Item>
      )}
    />
  );
};

export default InputSearch;
