import styled, { css } from 'styled-components';

const darkModeSyles = css`
  .cls-1 {
    fill: #4f4f51;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #4f4f51;
  }

  .cls-5 {
    fill: #4f4f51;
  }

  .cls-6 {
    fill: #b6b8ba;
  }
`;

export const Wrapper = styled.div<{ $big?: boolean; $white?: boolean }>`
  ${({ $white }) =>
    $white &&
    css`
      ${darkModeSyles}
    `};

  @media (prefers-color-scheme: dark) {
    ${darkModeSyles}
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      width: ${({ $big }) => ($big ? '180px' : '80px')};

      height: auto;

      path {
        will-change: fill;
        transition: fill 0.3s ease;
      }
    }
  }
`;
